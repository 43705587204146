.Footer{
  background: #FFFFFF;
  border-top: 1px solid rgba(51, 157, 255, 0.2);
  padding: 80px 0 0 0;
  .top{
    .menu-box{
      display: flex;
      align-items: center;

      a{
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        text-transform: uppercase;
        color: #072477;
      }
    }

    .contact-box{
      border-bottom: 1px solid rgba(51, 157, 255, 0.2);
      padding-bottom: 50px;
      display: flex;
      align-items: end;
      margin-top: 30px;

      .contact{
        .info{
          font-weight: 400;
          font-size: 40px;
          line-height: 130%;
          text-transform: uppercase;
          color: #1E1E1E;
        }
        .commit{
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #606981;
        }
      }

      .contact-link{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          margin: 0 8px;
        }
      }

      .contact-map{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #1E1E1E;
        .name{
          margin-bottom: 28px;
        }
      }
    }
  }

  .bottom{
    padding: 40px 0 62px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #999999;
    .top-text{
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1440px) {
  .Footer{
    padding: 60px 0 0 0;
    .top{
      .menu-box{
        img{
          width: 150px;
        }
        a{
          font-size: 13.5px;
          line-height: 100%;
        }
      }

      .contact-box{
        padding-bottom:  40px;
        margin-top: 22px;
        .contact{
          .info{
            font-size: 35px;
          }
          .commit{
            font-size: 12px;
          }
        }
        .contact-link{
          a{
            margin: 0 6px;
          }
        }

        .contact-map{
          font-size: 12px;
          .name{
            margin-bottom: 20px;
          }
        }
      }
    }

    .bottom{
      padding: 30px 0 45px 0;
      font-size: 9px;
      .top-text{
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 576px) {

  .Footer{
    padding: 34px 0 0 0;
    .top{

      .menu-box{
        a{
          font-size: 18px;
          line-height: 100%;
          margin-top: 35px !important;
          display: inline-block;
        }
      }

      .contact-box{
        padding-bottom: 34px;
        align-items: end;
        margin-top: 30px;

        .contact{
          margin-bottom: 20px;
          .info{
            font-size: 40px;
            line-height: 130%;
          }
          .commit{
            font-size: 16px;
            line-height: 150%;
          }
        }

        .contact-link{
          margin-top: 10px;
          a{
            margin: initial;
            margin-right: 16px;
          }
        }

        .contact-map{
          margin-top: 30px;
          font-size: 16px;
          line-height: 150%;
          .name{
            margin-bottom: 28px;
          }
        }
      }
    }

    .bottom{
      padding: 30px 0;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      color: #999999;
      .top-text{
        margin-bottom: 60px;
      }
    }
  }
}