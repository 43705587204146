.Navbar {

  .desktop-navbar {
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: white;
    box-shadow: 0px -6.75px 29.25px rgba(7, 36, 119, 0.28);
    z-index: 111;
    .content{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
    }

    .left-box {
      display: flex;
      align-items: center;

      .menu {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-left: 90px;

        li {
          margin-right: 76px;

          a {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #959BA6;
            position: relative;

            &:before {
              content: "";
              width: 0;
              height: 4px;
              background-color: #959BA6;
              position: absolute;
              bottom: -5px;
              transition: 0.3s all ease-out;
            }

            &:hover {
              &:before {
                width: 100%;
              }
            }
          }

          .active-menu {
            color: black;

            &:before {
              content: "";
              width: 100%;
              height: 4px;
              background-color: #203D91;
              position: absolute;
              bottom: -5px;
              transition: 0.3s all ease-out;
            }
          }
        }
      }
    }

    .right-box {
      display: flex;

      .application-btn {
        width: 207px;
        height: 44px;
        background: linear-gradient(90deg, #072477 -73.19%, #339DFF 100%);
        border-radius: 6px;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        transition: 0.3s all linear;

        &:hover {
          background: linear-gradient(90deg, #072477 -73.19%, #072477 100%);
        }
      }

      .language-box {
        margin-left: 14px;
        width: 62px;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 6px;
        position: relative;
        z-index: 1111111111;

        .selected-language{
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: #747474;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          cursor: pointer;
        }

        img {
          transition: 0.2s all linear;
        }

        img.close {
          transform: rotate(0);
        }

        img.open {
          transform: rotate(-180deg);
        }

        .select {
          position: absolute;
          bottom: -70px;
          width: 100%;
          border: 1px solid #DEDEDE;
          transition: 0.3s all linear;
          background: #FFFFFF;

          .language {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            cursor: pointer;
            text-transform: uppercase;

            &:hover{
              background-color: #EBF5FF;
            }
          }
        }

        .select.open-lang {
          display: block;
        }

        .select.close-lang {
          display: none;
        }

      }

  }

  }

  .mobile-navbar{
    display: none;
  }

  .mobile-menu{
    display: none !important;
  }

}

.modal-open {
  .modal {
    border-radius: 17px !important;
    overflow: hidden !important;
    .modal-dialog{
      z-index: 111111111111111 !important;
      .modal-content {
        border-radius: 17px !important;
        width: 435px;
        .modal-body {
          padding: 75px 51px ;
          background: #FFFFFF;
          border-radius: 17px !important;

          .form-title{
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: #000000;
            margin-bottom: 50px;
          }

          .application-form{
            input{
              width: 100%;
              border: 1px solid #DEDEDE;
              border-radius: 6px;
              padding: 20px 40px;
              margin-bottom: 10px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;

              &:focus-visible{
                outline: none;
                color: black;
              }
            }

            .form-btn{
              background: linear-gradient(90deg, #072477 -27.79%, #339DFF 100%);
              border-radius: 6px;
              border: none;
              width: 100%;
              padding: 20px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: white;
              margin-top: 20px;
              transition: 0.3s all linear;
              &:hover{
                background: linear-gradient(90deg, #072477 -73.19%, #072477 100%);
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {

  .Navbar{

    .desktop-navbar {
      .content{
        height: 80px;
      }

      .left-box {
        .menu {
          margin-left: 67px;
          li {
            margin-right: 57px;
            a {
              font-size: 14px;
              line-height: 15px;
              &:before {
                height: 3px;
                bottom: -5px;
              }
            }

            .active-menu {
              &:before {
                height: 3px;
              }
            }
          }
        }
      }

      .right-box {
        .application-btn {
          width: 180px;
          height: 44px;
          border-radius: 4.5px;
          font-size: 14px;
          line-height: 18px;
        }

        .language-box {
          margin-left: 10px;
          width: 62px;
          height: 44px;
          border-radius: 4.5px;
          .selected-language{
            font-size: 14px;
            line-height: 18px;
          }

          .select {
            bottom: -70px;
            .language {
              height: 30px;
              &:hover{
                background-color: #EBF5FF;
              }
            }
          }

          .select.open-lang {
            display: block;
          }

          .select.close-lang {
            display: none;
          }

        }

      }

    }
  }

  .modal-open {
    .modal {
      border-radius: 12px !important;
      .modal-dialog{
        .modal-content {
          border-radius: 12px !important;
          width: 400px;
          .modal-body {
            padding: 60px 35px ;
            border-radius: 12px !important;
            .form-title{
              font-size: 20px;
              line-height: 25px;
              margin-bottom: 40px;
            }

            .application-form{
              input{
                border-radius: 6px;
                padding: 18px 25px;
                font-size: 14px;
                line-height: 20px;
              }

              .form-btn{
                padding: 18px;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 576px) {

  .Navbar{

    .desktop-navbar{
      display: none;
    }

    .mobile-navbar{
      background-color: white;
      display: block;
      box-shadow: 0px -9px 39px rgba(7, 36, 119, 0.28);
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100000000000 !important;
      .content{
        padding: 19px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right-box {
          display: flex;

          .language-box {
            margin-right: 10px;
            width: 62px;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #DEDEDE;
            border-radius: 6px;
            position: relative;
            z-index: 1111111111;

            .selected-language{
              width: 100%;
              height: 100%;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              color: #747474;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
            }

            img {
              transition: 0.2s all linear;
            }

            img.close {
              transform: rotate(0);
            }

            img.open {
              transform: rotate(-180deg);
            }

            .select {
              position: absolute;
              bottom: -70px;
              width: 100%;
              border: 1px solid #DEDEDE;
              transition: 0.3s all linear;
              background: #FFFFFF;

              .language {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                cursor: pointer;
                text-transform: uppercase;

                &:hover{
                  background-color: #DEDEDE;
                }
              }
            }

            .select.open-lang {
              display: block;
            }

            .select.close-lang {
              display: none;
            }

          }

          .menu-btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 62px;
            height: 44px;
            cursor: pointer;
            transition: all .5s ease-in-out;
            border: 1px solid #DEDEDE;
            border-radius: 6px;
          }
          .menu-btn__burger {
            width: 27px;
            height: 4px;
            background-color: #072477;
            border-radius: 5px;
            transition: all .5s ease-in-out;
          }
          .menu-btn__burger::before,
          .menu-btn__burger::after {
            content: '';
            position: absolute;
            width: 27px;
            height: 4px;
            background: #072477;
            border-radius: 5px;
            transition: all .5s ease-in-out;
          }
          .menu-btn__burger::before {
            transform: translateY(-7px);
          }
          .menu-btn__burger::after {
            transform: translateY(7px);
          }
          /* ANIMATION */
          .menu-btn.open .menu-btn__burger {
            transform: translateX(-50px);
            background: transparent;
            box-shadow: none;
          }
          .menu-btn.open .menu-btn__burger::before {
            transform: rotate(45deg) translate(35px, -35px);
          }
          .menu-btn.open .menu-btn__burger::after {
            transform: rotate(-45deg) translate(35px, 35px);
          }

        }
      }
    }

    .mobile-menu{
      display: block !important;
      background-color: white;
      position: fixed;
      top: 81px;
      width: 100%;
      height: calc(100vh - 80px);
      transition: 0.3s all linear;
      overflow: hidden;
      z-index: 111111;

      .top{
        padding: 0 30px;
        padding-top: 5px;
        .menu {
          padding: 0;
          margin: 0;
          list-style-type: none;
          li {
            margin-top: 4vh;
            a {
              font-weight: 400;
              font-size: 40px;
              line-height: 49px;
              color: #959BA6;
              position: relative;

              &:before {
                content: "";
                width: 0;
                height: 4px;
                background-color: #959BA6;
                position: absolute;
                bottom: -5px;
                transition: 0.3s all ease-out;
              }

              &:hover {
                &:before {
                  width: 100%;
                }
              }
            }

            .active-menu {
              color: black;

              &:before {
                content: "";
                width: 100%;
                height: 4px;
                background-color: #203D91;
                position: absolute;
                bottom: -5px;
                transition: 0.3s all ease-out;
              }
            }
          }
        }
      }

      .bottom{
        position: absolute;
        bottom: 50px;
        width: 100%;
        padding: 0 30px;

        .contact-box{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 40px;
          img{
            margin: 0 8px;
          }
        }

        .application-btn {
          width: 100%;
          height: 44px;
          background: linear-gradient(90deg, #072477 -73.19%, #339DFF 100%);
          border-radius: 6px;
          border: none;
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          transition: 0.3s all linear;

          &:hover {
            background: linear-gradient(90deg, #072477 -73.19%, #072477 100%);
          }
        }
      }

    }

    .show-menu{
      left: 0 !important;
    }

    .close-menu{
      left: -100% !important;
    }
  }

}



