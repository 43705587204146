.Lider-farmasevtika{
   padding: 70px 0;

   .left-box{
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     .title{
       font-weight: 400;
       font-size: 40px;
       line-height: 120%;
       text-transform: uppercase;
       color: #000000;
     }
     .commit{
       margin-top: 30px;
       font-weight: 400;
       font-size: 20px;
       line-height: 150%;
       color: #606981;
     }
   }

   .right-box{
     height: 100%;
     display: flex;
     align-items: center;
   }
 }

@media (max-width: 1440px) {
  .Lider-farmasevtika{
    padding: 50px 0;
    padding-bottom: 100px;

    .left-box{
      height: 100%;
      .title{
        font-size: 30px;
      }
      .commit{
        margin-top: 22px;
        font-size: 15px;
      }
    }
    .right-box{

    }
  }
}

@media (max-width: 576px) {

  .Lider-farmasevtika{
    padding: 40px 0;

    .left-box{
      height: initial;
      .title{
        font-size: 25px;
        line-height: 120%;
      }
      .commit{
        margin-top: 20px;
        font-size: 15px;
        line-height: 150%;
      }
    }

    .right-box{
      margin-top: 30px;
      height: initial;
    }
  }

}