.Full-range-of-services{
    .right-box{
      height: 100%;
      display: flex;
      align-items: center;
      .title{
        font-weight: 400;
        font-size: 40px;
        line-height: 105%;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 47px;
      }

      .service-box{
        margin-bottom: 22px;
        .service-title{
          font-weight: bold;
          font-size: 20px;
          line-height: 150%;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 7px;
        }
        .service-commit{
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #606981;
        }
      }
    }
}

@media (max-width: 1440px) {
  .Full-range-of-services{
    .left-box{
      img{
        width: 90%;
      }
    }
    .right-box{
      .title{
        font-size: 30px;
        margin-bottom: 35px;
      }
      .service-box{
        margin-bottom: 16px;
        .service-title{
          font-size: 15px;
          margin-top: 15px;
          margin-bottom: 5px;
        }
        .service-commit{
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Full-range-of-services{

    .left-box{
      display: none;
    }

    .right-box{
      .title{
        font-size: 25px;
        line-height: 105%;
        margin-bottom: 29px;
      }

      .service-box{
        margin-bottom: 14px;
        img{
          height: 50px;
        }
        .service-title{
          font-size: 16px;
          line-height: 150%;
          margin-top: 12.5px;
          margin-bottom: 4px;
        }
        .service-commit{
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}