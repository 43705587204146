.New-horizons{
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
  }

  .commit{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #606981;
  }

  .horizons-box{
    margin-top: 60px;
    .col-md-4{
      margin-bottom: 24px;
    }
    .horizon-card{
      height: 100%;
      background: #D6EBFF;
      border-radius: 15px;
      padding: 30px 40px;
      display: flex;
      justify-content: space-between;
      .card-title{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #000000;
      }
    }

    .bg-gradient-box{
      background: linear-gradient(90deg, #072477 -73.5%, #339DFF 100%);
      .card-title{
        color: white;
      }
    }
  }

}

@media (max-width: 1440px) {

  .New-horizons{
    .title{
      font-size: 30px;
    }

    .commit{
      font-size: 15px;
    }

    .horizons-box{
      margin-top: 47px;
      .col-md-4{
        margin-bottom: 24px;
      }
      .horizon-card{
        border-radius: 12px;
        padding: 22px 30px;
        .card-title{
          font-size: 15px;
        }
      }
    }

  }

}

@media (max-width: 576px) {

  .New-horizons{
    .title{
      font-size: 25px;
      line-height: 120%;
      margin-bottom: 20px;
    }

    .commit{
      font-size: 15px;
      line-height: 150%;
    }

    .horizons-box{
      margin-top: 0px;
      .col-md-4{
        margin-bottom: 0px;
        margin-top: 20px;
      }
      .horizon-card{
        border-radius: 12px;
        padding: 22px 30px;
        .card-title{
          font-size: 15px;
        }
      }
    }
  }

}