.Health-click-away{
  .top{
    padding: 100px 0;
    .left-side{
      height: 100%;
      .title{
        font-weight: 400;
        font-size: 40px;
        line-height: 120%;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 30px;
      }
      .commit{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #606981;
      }
    }
    .right-side{
      height: 100%;
      background: {
        image: url("../../assets/images/health-click-away/bg-health.png");
        color: rgba(51, 157, 255, 0.1);;
        position-y: center;
        size: cover;
        position-x: center;
        repeat: no-repeat;
      };
      border-radius: 15px;
    }
  }

  .info-text-box{
    padding: 50px 0;
    border-top: 1px solid rgba(51, 157, 255, 0.4);
    border-bottom: 1px solid rgba(51, 157, 255, 0.4);
    .info{
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #606981;
    }
  }
}

@media (max-width: 1440px) {

  .Health-click-away{
    .top{
      padding: 75px 0;
      .left-side{
        .title{
          font-size: 30px;
          margin-bottom: 22px;
        }
        .commit{
          font-size: 15px;
        }
      }
      .right-side{
        background: {
          image: url("../../assets/images/health-click-away/1440-bg-health.png");
        };
        border-radius: 12px;
      }
    }

    .info-text-box{
      padding: 37px 0;
      .info{
        font-size: 15px;
      }
    }
  }

}

@media (max-width: 576px) {
  .Health-click-away{
    .top{
      padding: 40px 0;
      .left-side{
        height: initial;
        .title{
          font-size: 25px;
          line-height: 120%;
          margin-bottom: 20px;
        }
        .commit{
          font-size: 15px;
          line-height: 150%;
        }
      }
      .right-side{
        height: 340px;
        margin-top: 40px;
        background: {
          image: url("../../assets/images/health-click-away/mobile-bg-health.png");
          color: rgba(51, 157, 255, 0.1);;
          position-y: center;
          size: 100%;
          position-x: center;
          repeat: no-repeat;
        };
        border-radius: 11px;
      }
    }

    .info-text-box{
      padding: 40px 0;
      .info{
        font-size: 15px;
      }
    }
  }
}