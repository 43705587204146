.Green-texnologies{
  padding-top: 40px;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
  }
  .commit{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #606981;
  }

  .green-cards{
    margin-top: 30px;

    .card-box{
      background: rgba(51, 157, 255, 0.2);
      border-radius: 15px;
      padding: 30px 40px;
      height: 100%;
      display: flex;
      align-items: center;

      .info{
        height: 100%;
        display: flex;
        align-items: center;
        .card-title{
          font-weight: 400;
          font-size: 20px;
          line-height: 130%;
          color: #000000;
          span{
            font-weight: bold;
          }
        }
      }

      .img-box{
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .bg-gradient-box{
      background: linear-gradient(90deg, #072477 -73.5%, #339DFF 100%);
      .info{
        .card-title{
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1440px) {

  .Green-texnologies{
    .title{
      font-size: 30px;
    }
    .commit{
      font-size: 15px;
    }

    .green-cards{

      margin-top: 22px;

      .card-box{
        border-radius: 12px;
        padding: 22px 30px;
        .info{
          .card-title{
            font-size: 15px;
          }
        }
        .img-box{
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Green-texnologies{
    margin-top: 50px;
    .title{
      font-size: 25px;
      margin-bottom: 20px;
    }
    .commit{
      font-size: 15px;
    }

    .green-cards{
      margin-top: 20px;

      .col-md-4{
        margin-bottom: 20px;
      }
      .card-box{
        border-radius: 12px;
        padding: 24px 32px;

        .info{
          .card-title{
            font-size: 16px;
          }
        }

        .img-box{
          img{
            width: 85%;
          }
        }
      }
    }
  }
}