.Grand-logistic-area {
  padding: 80px 0 70px 0;
  .top {
    .title {
      font-weight: 400;
      font-size: 40px;
      line-height: 49px;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
    .area-info {
      .number-text {
        font-weight: 300;
        font-size: 100px;
        line-height: 98.6%;
        letter-spacing: -0.02em;
        color: #339dff;
      }
      .commit-text {
        margin-top: 4px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #606981;
      }
    }
  }

  .center {
    margin-top: 100px;
    .content {
      display: flex;
    }
    .left-box {
      width: 60%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background: linear-gradient(180deg, #072477 -86.54%, #339dff 100.06%);
      // padding: 50px 40px 50px 80px;
      display: flex;
      align-items: center;
      padding-left: 80px;
      padding-right: 50px;
      height: 300px;
      .title {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .right-box {
      width: 40%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      background-image: url("../../assets/images/video-bg.png");
      background-size: cover;
      background-repeat: no-repeat;

      .play-icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;

        .icon-play {
          width: 50px;
          height: 50px;
          background-color: #072477;
          -webkit-mask-image: url("../../assets/images/modal-video/icon-play.svg");
          mask-image: url("../../assets/images/modal-video/icon-play.svg");
          mask-repeat: no-repeat;
          mask-size: contain;
          mask-position: center center;
          transition: 0.2s all linear;

          &:hover {
            background-color: #339dff;
          }
        }
      }
    }
  }

  .bottom {
    .row {
      margin-top: 100px;
      .col-md-4 {
        margin-bottom: 23px;
      }
    }

    .area-info-box {
      background: rgba(51, 157, 255, 0.2);
      border-radius: 15px;
      padding: 55px 36px;
      height: 100%;

      .img-box {
        height: 215px;
      }

      .title {
        font-weight: 400;
        font-size: 32px;
        line-height: 150%;
        color: #000000;
        margin-top: 28px;
        margin-bottom: 20px;
      }
      .commit {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #1e1e1e;
      }
    }

    .bg-gradient-box {
      background: linear-gradient(90deg, #072477 -73.5%, #339dff 100%);

      .title,
      .commit {
        color: white;
      }
    }
  }
}

@media (max-width: 1440px) {
  .Grand-logistic-area {
    padding: 55px 0 50px 0;
    .top {
      .title {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 37px;
      }
      .area-info {
        .number-text {
          font-size: 75px;
        }
        .commit-text {
          margin-top: 3px;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }

    .center {
      margin-top: 75px;
      .left-box {
        width: 60%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        // padding: 40px 30px 40px 70px;
        display: flex;
        align-items: center;
        padding-left: 70px;
        padding-right: 30px;
        height: 200px;
        .title {
          font-size: 30px;
        }
      }
      .right-box {
        width: 40%;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;

        background-image: url("../../assets/images/video-bg-1440.png");
        background-size: cover;

        .play-icon-box {
          width: 90px;
          height: 90px;

          .icon-play {
            width: 37.5px;
            height: 37.5px;
          }
        }
      }
    }

    .bottom {
      .row {
        margin-top: 75px;
        .col-md-4 {
          margin-bottom: 24px;
        }
      }

      .area-info-box {
        border-radius: 12px;
        padding: 41px 27px;
        .img-box {
          height: 160px;
          img {
            width: 50%;
          }
        }
        .title {
          font-size: 27px;
          margin-top: 21px;
          margin-bottom: 14px;
        }
        .commit {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Grand-logistic-area {
    padding: 50px 0 14px 0;
    .top {
      .title {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 4px;
      }
      .area-info {
        margin-top: 26px;
        .number-text {
          font-size: 79px;
          line-height: 98.6%;
        }
        .commit-text {
          margin-top: 2px;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }

    .center {
      margin-top: 30px;
      .left-box {
        width: 60%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        // padding: 20px 10px 20px 30px;
        padding-left: 30px;
        padding-right: 10px;
        height: 150px;
        .title {
          font-size: 14px;
        }
      }
      .right-box {
        width: 40%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        background-image: url("../../assets/images/video-bg-mobile.png");
        background-size: cover;

        .play-icon-box {
          width: 60px;
          height: 60px;

          .icon-play {
            width: 17.5px;
            height: 17.5px;
          }
        }
      }
    }

    .bottom {
      .row {
        margin-top: 30px;
        .col-md-4 {
          margin-bottom: 20px;
        }
      }

      .area-info-box {
        border-radius: 6px;
        padding: 30px 25px;
        height: 100%;
        .img-box {
          height: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: initial;
            width: 150px;
          }
        }
        .title {
          font-size: 30px;
          margin-top: 23px;
          margin-bottom: 15px;
        }
        .commit {
          font-size: 16px;
        }
      }
    }
  }
}
