.Our-contacts{
  padding-top: 80px;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
  }
  .commit{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #606981;
  }

  .contacts{
    margin-top: 50px;
    .contact-box{
      display: flex;
      justify-content: space-between;
      padding: 50px 0;
      border-top: 1px solid rgba(51, 157, 255, 0.4);
      .left-side{
        .contact-title{
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #606981;
        }
      }
      .right-side{
        display: flex;
        justify-content: end;
        .contact{
          margin-left: 100px;
          .head{
            font-weight: 400;
            font-size: 40px;
            line-height: 130%;
            color: #339DFF;
          }
          .body{
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #606981;
          }
        }
      }
    }

    .contact-box:nth-child(3){
      border-bottom: 1px solid rgba(51, 157, 255, 0.4);
    }
  }
}

@media (max-width: 1440px) {
  .Our-contacts{
    padding-top: 60px;
    .title{
      font-size: 30px;
      margin-bottom: 23px;
    }
    .commit{
      font-size: 15px;
    }

    .contacts{
      margin-top: 37px;
      .contact-box{
        padding: 38px 0;
        .left-side{
          .contact-title{
            font-size: 15px;
          }
        }
        .right-side{
          .contact{
            margin-left: 80px;
            .head{
              font-size: 30px;
            }
            .body{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Our-contacts{
    padding-top: 40px;

    .title{
      font-size: 25px;
      margin-bottom: 20px;
    }
    .commit{
      font-size: 15px;
    }

    .contacts{
      margin-top: 30px;
      .contact-box{
        display: block;
        padding: 20px 0;
        .left-side{
          .contact-title{
            font-size: 15px;
            margin-bottom: 20px;
          }
        }
        .right-side{
          display: block;
          .contact{
            margin-left: initial;
            .head{
              font-size: 40px;
            }
            .body{
              font-size: 16px;
            }
            .email, .map{
              font-size: 24px;
              font-weight: 300;
            }
          }
        }
      }

      .contact-box:nth-child(1){
        .right-side{
          .contact:nth-child(1){
            margin-bottom: 10px;
          }
        }
      }

      .contact-box:nth-child(3){
        border-bottom: 1px solid rgba(51, 157, 255, 0.4);
      }
    }


  }
}
