.Home-header{
  height: calc(100vh - 80px);
  margin-top: 80px;
  background: {
    image: url("../../assets/images/home-header/bg-home-header.png");
    position-x: right;
    position-y: 0;
    size: cover;
    repeat: no-repeat;
  };
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: -1 !important;

  .top{
    margin-bottom: 100px;
    .title{
      font-weight: 700;
      font-size: 64px;
      line-height: 105%;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .commit{
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #FFFFFF;
    }
  }

  .bottom{
    width: 100%;
    position: absolute;
    bottom: 80px;
    .head-card{
      padding: 20px 36px;
      display: flex;
      align-items: center;
      height: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      width: 100%;
      .text{
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #072477;

        span{
          color: #339DFF;
        }
      }
    }

    .col-3:nth-child(1){
      padding-right: 8px;
    }
    .col-3:nth-child(2), .col-3:nth-child(3){
      padding-left: 8px;
      padding-right: 8px;
    }
    .col-3:nth-child(4){
      padding-left: 8px;
    }
  }

  .carousel-box{
    display: none;
  }
}

@media (max-width: 1440px) {
  .Home-header{
    height: calc(100vh - 80px);
    background: {
      image: url("../../assets/images/home-header/bg-home-header.png");
      position-x: right;
      position-y: 0px;
      size: cover;
      repeat: no-repeat;
    };

    .top{
      margin-bottom: 100px;
      .title{
        font-size: 48px;
        margin-bottom: 23px;
      }
      .commit{
        font-size: 12px;
      }
    }

    .bottom{
      bottom: 40px;
      .head-card{
        padding: 30px 27px;
        border-radius: 7px;
        .text{
          font-size: 13px;
        }
      }

      .col-3:nth-child(1){
        padding-right: 8px;
      }
      .col-3:nth-child(2), .col-3:nth-child(3){
        padding-left: 8px;
        padding-right: 8px;
      }
      .col-3:nth-child(4){
        padding-left: 8px;
      }
    }
  }
}

@media (max-width: 576px) {
  .Home-header{
    margin-top: 82px;
    height: calc(100vh - 82px);
    background: {
      image: url("../../assets/images/home-header/mobile-bg-home-header.png");
      position-x: right;
      color: #072477;
      /*position-y: 0;*/
      position-y: center;
      size: cover;
      repeat: no-repeat;
    };
    display: block;
    .top{
      padding-top: 50px;
      margin-bottom: 0px;
      .title{
        font-size: 32px;
        margin-bottom: 22px;
      }
      .commit{
        font-size: 12px;
      }
    }

    .bottom{
      display: none;
    }

    .carousel-box{
      display: block;
      width: 100%;
      position: absolute;
      bottom: 50px;
      .Carousel-item{
        padding: 0 5px;
        .head-card{
          padding: 20px 27px;
          display: flex;
          align-items: center;
          height: 100px;
          background: #FFFFFF;
          border-radius: 10px;
          width: 100%;
          .text{
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            text-align: center;
            color: #072477;
            span{
              color: #339DFF;
            }
          }
        }
      }

      .slick-dots{
        bottom: -30px;
        li{
          width: 10px;
          height: 10px;
          background-color: #339DFF;
          border-radius: 50%;
          button{
            &::before{
              content: "";
            }
          }
        }
        .slick-active{
          background-color: white;
          button{
            &::before{
              content: "";
              color: transparent !important;
            }
          }
        }
      }
    }
  }
}