.Loading{
  background-color: white;
  height: 100vh;
  z-index: 9999999999999999 !important;
}

@media (max-width: 576px) {
  .Loading{
    background-color: white;
    height: 100vh;
    z-index: 9999999999999999 !important;
  }
}
