.Logistic-hub-process{
  margin-top: 78px;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 40px;
  }

  .accordion-box{
    border-bottom: 1px solid rgba(51, 157, 255, 0.4);
    border-top: 1px solid rgba(51, 157, 255, 0.4);

    .accordion{
      .accordion-item{
        padding: 40px 0;
        border-top: 1px solid rgba(51, 157, 255, 0.4);
        border-bottom: 1px solid rgba(51, 157, 255, 0.4);
        border-radius: 0;
        .accordion-header{
          .accordion-button{
            content: "A";
            font-weight: 400;
            font-size: 36px;
            line-height: 150%;
            color: #606981;
            padding: 0;
            &:focus{
              box-shadow: none;
              background-color: transparent;
            }

            &::after{
              width: 50px;
              height: 50px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-image: url("../../assets/images/logistic-hub-process/show-plus.png");
              background-position: center center;
              background-size: cover;
              transform: rotate(45deg);
            }
          }

          .accordion-button.collapsed{
            &::after{
              transform: rotate(0);
            }
          }

          .accordion-button:not(.collapsed){
            background-color: white;
            box-shadow: none;
          }
        }

        .accordion-body{
          padding: 0;
          ul{
            margin: 0;
            padding: 0;
            margin-top: 30px;
            li{
              list-style-type: none;
              margin: 0;
              font-weight: 400;
              font-size: 20px;
              line-height: 150%;
              color: #606981;
            }
          }
        }

        .accordion-button.collapsed{
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .Logistic-hub-process{
    margin-top: 60px;
    .title{
      font-size: 30px;
      margin-bottom: 30px;
    }
    .accordion-box{
      .accordion{
        .accordion-item{
          padding: 30px 0;
          .accordion-header{
            .accordion-button{
              font-size: 27px;
              &::after{
                width: 40px;
                height: 40px;
                background-image: url("../../assets/images/logistic-hub-process/show-plus.png");
              }
            }
          }
          .accordion-body{
            ul{
              margin-top: 22px;
              li{
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {

  .Logistic-hub-process{
    margin-top: 50px;
    .title{
      font-size: 25px;
      margin-bottom: 45px;
    }

    .accordion-box{
      .accordion{
        .accordion-item{
          padding: 20px 0;
          .accordion-header{
            .accordion-button{
              font-size: 18px;
              &::after{
                width: 40px;
                height: 40px;
                background-image: url("../../assets/images/logistic-hub-process/show-plus.png");
              }
            }
          }

          .accordion-body{
            ul{
              margin-top: 20px;
              li{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

}