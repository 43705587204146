.Professional-farmaseftika{
  padding: 100px 0;
    .left-side{
      height: 100%;
      .title{
        font-weight: 400;
        font-size: 40px;
        line-height: 120%;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 30px;
      }
      .commit{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #606981;
      }
    }

  .right-side{
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1440px) {
  .Professional-farmaseftika{
    padding: 100px 0;
    padding-bottom: 0;
    .left-side{
      .title{
        font-size: 30px;
        margin-bottom: 22px;
      }
      .commit{
        font-size: 15px;
      }
    }
    .right-side{

    }
  }
}

@media (max-width: 576px) {
  .Professional-farmaseftika{
    padding: 40px 0;
    padding-bottom: 0;
    .left-side{
      height: initial;
      .title{
        font-size: 25px;
        line-height: 120%;
        margin-bottom: 20px;
      }
      .commit{
        font-size: 15px;
        line-height: 150%;
      }
    }

    .right-side{
      height: initial;
      display: flex;
      align-items: center;
    }
  }
}