* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;

    /*-moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;*/
}

body{
    overflow-x: hidden !important;
}

body::-webkit-scrollbar {
    width: initial;
    background-color: #EBF5FF;
}

body::-webkit-scrollbar-track {
    height: 20px;
    background-color: #EBF5FF;
}

body::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #072477 -73.19%, #339DFF 100%);
    border-radius: 1px;
}

@font-face {
    font-family: "Montserrat";
    src: url("font/Montserrat/Montserrat-Medium.ttf") format('truetype');
}

.ant-back-top {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
    border-radius: 4px;
    background: linear-gradient(90deg, #072477 -73.19%, #339DFF 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px !important;
}

@media (max-width: 576px) {
    .ant-back-top{
        right: 20px;
    }
    .row{
        overflow-x: hidden !important;
    }
}

.Toastify__toast{
    background: linear-gradient(90deg, #072477 -73.19%, #339DFF 100%) !important;
    top: 82px;
}

.Toastify__progress-bar--success {
    background: white !important;
}

.Toastify__toast--success, svg {
    fill: white;
}

.Toastify__progress-bar--error {
    background-color: white !important;
}

.Toastify__progress-bar--warning {
    background-color: white !important;
}

html {
    scroll-behavior: smooth;
}

