.Hub-on-the-map{
  padding-top: 30px;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
  }
  .map-box{
    height: 900px;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1440px) {
  .Hub-on-the-map{
    padding-top: 30px;
    .title{
      font-size: 30px;
      margin-bottom: 30px;
    }
    .map-box{
      height: 500px;
    }
  }
}

@media (max-width: 576px) {
  .Hub-on-the-map{
    padding-top: 30px;
    .title{
      font-size: 25px;
      margin-bottom: 20px;
    }
    .map-box{
      height: 50vh;
    }
  }
}