.About-header{
  padding: 186px 0 80px 0;
  margin-top: 80px;
  background: {
    image: url("../../assets/images/about-header/bg-about-header.png"), linear-gradient(180deg, #072477 0%, #339);
    size: cover;
    position-x: right;
    position-y: bottom;
  };

  .title{
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .commit{
    font-weight: lighter;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
  }
}

@media (max-width: 1440px) {
  .About-header{
    padding: 140px 0 60px 0;
    background: {
      image: url("../../assets/images/about-header/bg-about-header.png"), linear-gradient(180deg, #072477 0%, #339);
      size: cover;
    };
    .title{
      font-size: 48px;
    }
    .commit{
      font-size: 30px;
    }
  }
}

@media (max-width: 576px) {
  .About-header{
    margin-top: 81px;
    padding: 50px 0 80px 0;
    background: {
      image: url("../../assets/images/about-header/mobile-bg-about-header.png"), linear-gradient(180deg, #072477 0%, #339);
      size: cover;
      position-x: right;
      position-y: bottom;
    };

    .title{
      font-size: 48px;
      line-height: 120%;
    }
    .commit{
      margin-top: 5px;
      font-size: 32px;
      line-height: 120%;
    }
  }
}