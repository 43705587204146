.Not-found-header {
  padding: 160px 0 30px 0;

  .title {
    font-weight: 700;
    font-size: 400px;
    line-height: 100%;
    text-transform: uppercase;
    color: #339DFF;
    text-align: center;
  }
}

@media (max-width: 1440px) {
  .Not-found-header {
    padding: 120px 0 30px 0;

    .title {
      font-weight: 700;
      font-size: 300px;
      line-height: 100%;
      text-transform: uppercase;
      color: #339DFF;
      text-align: center;
    }
  }
}

@media (max-width: 576px) {
  .Not-found-header {
    padding: 90px 0 30px 0;
    .title {
      font-size: 150px;
    }
  }
}

