.About-number-of-dorixona{
  .content{
    background: rgba(51, 157, 255, 0.1);
    border-radius: 20px;
    padding: 40px 0px;

    .left-side{
      .top, .bottom{
        display: flex;
        align-items: center;
        justify-content: space-around;

        .number-box{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .title{
            font-weight: 400;
            font-size: 90px;
            line-height: 98.6%;
            letter-spacing: -0.02em;
            color: #339DFF;
            margin-bottom: 18px;
          }
          .commit{
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            font-variant: all-small-caps;
            color: #606981;
          }
        }
      }

      .top{
        margin-bottom: 2px;
        .number-box{
          .title{
            font-size: 150px;
            line-height: 100%;
            margin-bottom: 0;
          }
        }
      }
    }

    .right-side{
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 50px;
      .info{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #606981;
      }
    }
  }

  .info-text-box{
    margin-top: 100px;
    padding: 50px 0;
    border-top: 1px solid rgba(51, 157, 255, 0.4);
    border-bottom: 1px solid rgba(51, 157, 255, 0.4);
    .info{
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #606981;
    }
  }
}

@media (max-width: 1440px) {

  .About-number-of-dorixona{
    .content{
      border-radius: 15px;
      padding: 30px 0px;

      .left-side{
        .top, .bottom{
          .number-box{
            .title{
              font-size: 67px;
              margin-bottom: 11px;
            }
            .commit{
              font-size: 15px;
              line-height: 22px;
            }
          }
        }

        .top{
          margin-bottom: 2px;
          .number-box{
            .title{
              font-size: 112px;
              margin-bottom: 0;
            }
          }
        }
      }

      .right-side{
        padding: 0 50px;
        .info{
          font-size: 15px;
        }
      }
    }

    .info-text-box{
      margin-top: 75px;
      padding: 37px 0;
      .info{
        font-size: 15px;
      }
    }
  }

}

@media (max-width: 576px) {
  .About-number-of-dorixona{
    .content{
      padding: 40px 30px;

      .left-side{
        .top, .bottom{
          .number-box{
            .title{
              font-size: 67px;
              line-height: 98.6%;
              margin-bottom: 10px;
            }
            .commit{
              font-size: 15px;
              line-height: 150%;
            }
          }
        }

        .top{
          margin-bottom: 10px;
          .number-box{
            .title{
              font-size: 112px;
              line-height: 100%;
              margin-bottom: 0;
            }
          }
        }
      }

      .right-side{
        margin-top: 33px;
        height: initial;
        display: flex;
        align-items: center;
        padding: 0;
        .info{
          font-size: 15px;
          line-height: 150%;
        }
      }
    }

    .info-text-box{
      margin-top: 50px;
      padding: 40px 0;
      .info{
        font-size: 15px;
        line-height: 150%;
      }
    }
  }
}