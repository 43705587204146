.Services-and-ready-solutions{
  padding: 100px 0 26px 0;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
  }
  .commit{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #606981;
  }

  .services{
    margin-top: 40px;
    .col-md-6{
      margin-bottom: 24px;
    }
    .service-box{
      padding: 40px;
      background: rgba(51, 157, 255, 0.2);
      border-radius: 15px;
      height: 100%;
      .info-box{
        height: 100%;
        .service-title{
          font-weight: 500;
          font-size: 36px;
          line-height: 120%;
          color: #000000;
          margin-bottom: 30px;
        }
        .service-commit{
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #1E1E1E;
        }
      }
      .img-box{
        display: flex;
        align-items: center;
        justify-content: end;
        height: 100%;
      }
    }

    .bg-gradient-box{
      background: linear-gradient(90deg, #072477 -73.5%, #339DFF 100%);
      .info-box{
        .service-title, .service-commit{
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .Services-and-ready-solutions{
    padding: 75px 0 15px 0;
    .title{
      font-size: 30px;
      margin-bottom: 22px;
    }
    .commit{
      font-size: 15px;
    }

    .services{
      margin-top: 30px;
      .col-md-6{
        margin-bottom: 24px;
      }
      .service-box{
        padding: 30px;
        border-radius: 12px;
        .info-box{
          .service-title{
            font-size: 27px;
            margin-bottom: 23px;
          }
          .service-commit{
            font-size: 15px;
          }
        }
        .img-box{
          img{
            width: 90%;
          }
        }
      }

      .bg-gradient-box{
        background: linear-gradient(90deg, #072477 -73.5%, #339DFF 100%);
        .info-box{
          .service-title, .service-commit{
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Services-and-ready-solutions{
    padding: 40px 0 40px 0;
    .title{
      font-size: 25px;
      margin-bottom: 20px;
    }
    .commit{
      font-size: 15px;
      line-height: 150%;
    }

    .services{
      margin-top: 40px;
      .col-md-6{
        margin-bottom: 10px;
      }
      .service-box{
        padding: 20px;
        border-radius: 8px;
        .info-box{
          .service-title{
            font-size: 18px;
            margin-bottom: 18px;
          }
          .service-commit{
            font-size: 10px;
          }
        }
        .img-box{
          img{
            width: 80%;
          }
        }
      }

      .col-md-6:nth-child(2) .service-box, .col-md-6:nth-child(4) .service-box, .col-md-6:nth-child(6) .service-box, .col-md-6:nth-child(8) .service-box{
        background: linear-gradient(90deg, #072477 -73.5%, #339DFF 100%);
        .info-box{
          .service-title, .service-commit{
            color: white;
          }
        }
      }

      .col-md-6:nth-child(1) .service-box, .col-md-6:nth-child(3) .service-box, .col-md-6:nth-child(5) .service-box, .col-md-6:nth-child(7) .service-box{
        background: rgba(51, 157, 255, 0.2);
        .info-box{
          .service-title, .service-commit{
            color: black;
          }
        }
      }

    }
  }
}