.How-to-order-services {
  padding: 100px 0;

  .content {
    border-radius: 20px;
    padding: 100px 150px;
    background: {
      image: url("../../assets/images/order-services/bg-order-services.png");
      color: #EBF5FF;
      position-y: bottom;
      position-x: right;
      repeat: no-repeat;
    };

    .title{
      font-weight: 400;
      font-size: 40px;
      line-height: 49px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 40px;
    }

    .commit-title{
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
    }

    ul{
      li{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #606981;
        margin-top: 20px;
      }
    }

    .contact-box{
      margin-top: 50px;
      display: flex;
      .contact{
        margin-right: 35px;
        .head{
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #595859;
        }
        .body{
          font-weight: 500;
          font-size: 18px;
          line-height: 130%;
          color: #000000;
        }
      }
    }

    .work-time{
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: #000000;
      margin-top: 40px;
    }

    .application-box{
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      border-radius: 17px;
      padding: 72px 50px;

      .form-title{
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 50px;
      }

      .application-form{
        input{
          width: 100%;
          border: 1px solid #DEDEDE;
          border-radius: 6px;
          padding: 20px 40px;
          margin-bottom: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          &:focus-visible{
            outline: none;
            color: black;
          }
        }

        .form-btn{
          background: linear-gradient(90deg, #072477 -27.79%, #339DFF 100%);
          border-radius: 6px;
          border: none;
          width: 100%;
          padding: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: white;
          margin-top: 20px;
          transition: 0.3s all linear;
          &:hover{
            background: linear-gradient(90deg, #072477 -73.19%, #072477 100%);
            border-radius: 6px;
          }
        }
      }
    }

  }
}


@media (max-width: 1440px) {
  .How-to-order-services {
    padding: 75px 0;

    .content {
      border-radius: 15px;
      padding: 75px 110px;
      background: {
        image: url("../../assets/images/order-services/1440-bg-order-services.png");
      };

      .title{
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 30px;
      }

      .commit-title{
        font-size: 15px;
      }

      ul{
        li{
          font-size: 12px;
          margin-top: 15px;
        }
      }

      .contact-box{
        margin-top: 37px;
        .contact{
          margin-right: 30px;
          .head{
            font-size: 12px;
          }
          .body{
            font-size: 15px;
          }
        }
      }

      .work-time{
        font-size: 15px;
        margin-top: 30px;
      }

      .application-box{
        border-radius: 12px;
        padding: 54px 38px;

        .form-title{
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 40px;
        }

        .application-form{
          input{
            border-radius: 4px;
            padding: 12px 20px;
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 18px;
          }

          .form-btn{
            border-radius: 4px;
            padding: 15px;
            font-size: 12px;
            line-height: 18px;
            margin-top: 15px;
          }
        }
      }

    }
  }
}

@media (max-width: 576px) {

  .How-to-order-services {
    padding: 50px 0;

    .content {
      border-radius: 10px;
      padding: 50px 30px;
      background: {
        image: url("../../assets/images/order-services/mobile-bg-order-services.png");
        color: #EBF5FF;
        position-y: bottom;
        position-x: right;
        repeat: no-repeat;
      };

      .title{
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      .commit-title{
        font-size: 12px;
        line-height: 150%;
      }

      ul{
        li{
          font-size: 12px;
          line-height: 150%;
          margin-top: 10px;
        }
      }

      .contact-box{
        margin-top: 20px;
        display: block;
        .contact{
          margin-right: 0px;
          margin-bottom: 13px;
          .head{
            font-size: 10px;
            line-height: 150%;
          }
          .body{
            font-size: 16px;
            line-height: 130%;
            font-weight: 600;
          }
        }
      }

      .work-time{
        font-size: 12px;
        line-height: 130%;
        margin-top: 20px;
      }

      .application-box{
        height: initial;
        margin-top: 22px;
        border-radius: 10px;
        padding: 30px;

        .form-title{
          font-size: 20px;
          line-height: 15px;
          margin-bottom: 20px;
        }

        .application-form{
          input{
            padding: 15px 20px;
            margin-bottom: 10px;
            font-size: 13px;
            line-height: 15px;

            &:focus-visible{
              outline: none;
              color: black;
            }
          }

          .form-btn{
            padding: 12px;
            font-size: 13px;
            line-height: 15px;
            margin-top: 20px;
          }
        }
      }

    }
  }

}