.Social-networks{
  padding-top: 100px;
  .title{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
  }

  .networks{
    margin-top: 40px;
    .network-card{
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(51, 157, 255, 0.2);
      border-radius: 15px;

      .network-image{
        span{
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }

      .network-title{
        font-weight: 400;
        font-size: 36px;
        line-height: 150%;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
      }
      .network-link{
        display: inline-block;
        padding: 15px 0;
        width: 100%;
        background: white;
        border-radius: 6px;
        text-align: center;
        margin-top: 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &:hover{
          background: linear-gradient(270deg, #339DFF 0%, #072477 132.39%);
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .Social-networks{
    padding-top: 75px;
    .title{
      font-size: 30px;
    }

    .networks{
      margin-top: 30px;
      .network-card{
        padding: 30px;
        .network-title{
          font-size: 27px;
          margin-bottom: 14px;
        }
        .network-link{
          padding: 12px 0;
          margin-top: 24px;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .Social-networks{
    padding-top: 30px;
    .title{
      font-size: 25px;
    }
    .networks{
      margin-top: 20px;
      .col-6{
        margin-bottom: 10px;
      }

      .col-6:nth-child(1), .col-6:nth-child(3){
        padding-right: 5px;
      }
      .col-6:nth-child(2), .col-6:nth-child(4){
        padding-left: 5px;
      }
      .network-card{
        padding: 21px;
        border-radius: 8px;
        height: 100%;
        .network-title{
          font-size: 19px;
          margin-bottom: 10px;
        }
        .network-link{
          padding: 8px 0;
          border-radius: 3px;
          margin-top: 17px;
          font-size: 9px;
          line-height: 13px;
        }
        .network-image{
          span{
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
          }
          img{
            width: 50%;
          }
        }
      }
    }
  }
}