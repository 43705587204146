@import 'node_modules/react-modal-video/scss/modal-video.scss';
/*@import "react-mo";*/

.Grand-logistic-modal-video {
  margin-bottom: 64px;

  .content {
    padding: 90px 76px 70px 76px;
    border-radius: 30px;
    background: {
      image: url("../../assets/images/modal-video/bg-modal-video.png");
      size: cover;
      repeat: no-repeat;
      position-x: right;
    };

    .commit {
      font-weight: 400;
      font-size: 40px;
      line-height: 150%;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .download-btn {
      display: inline-block;
      padding: 23px 67px;
      background: #FFFFFF;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #072477;
      transition: 0.2s all linear;

      &:hover {
        color: #339DFF;
      }
    }

    .play-icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;

      .icon-play {
        width: 50px;
        height: 50px;
        background-color: #072477;
        -webkit-mask-image: url('../../assets/images/modal-video/icon-play.svg');
        mask-image: url('../../assets/images/modal-video/icon-play.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center center;
        transition: 0.2s all linear;

        &:hover {
          background-color: #339DFF;
        }
      }
    }
  }

  .modal-video-movie-wrap {
    width: 100vw !important;
  }

}

@media (max-width: 1440px) {
  .Grand-logistic-modal-video {

    margin-bottom: 48px;

    .content {
      padding: 68px 57px 54px 57px;
      border-radius: 20px;
      background: {
        image: url("../../assets/images/modal-video/1440-bg-modal-video.png");
        size: cover;
      };

      .commit {
        font-size: 30px;
        margin-bottom: 15px;
      }

      .download-btn {
        padding: 17px 50px;
        border-radius: 4.5px;
        font-size: 12px;
        line-height: 18px;
      }

      .play-icon-box {
        width: 90px;
        height: 90px;

        .icon-play {
          width: 37.5px;
          height: 37.5px;
        }
      }

    }
  }

}

@media (max-width: 576px) {

  .Grand-logistic-modal-video {
    width: 100%;
    margin-bottom: 50px;

    .content {
      padding: 36px 30px 36px 30px;
      border-radius: 10px;
      background: {
        image: url("../../assets/images/modal-video/bg-modal-video.png");
        size: cover;
        position-x: right;
      };

      .commit {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      .download-btn {
        display: inline-block;
        padding: 9px 18px;
        font-size: 8px;
        line-height: 9.6px;

        &:hover {
          color: #339DFF;
        }
      }

      .play-icon-box {
        width: 60px;
        height: 60px;

        .icon-play {
          width: 17.5px;
          height: 17.5px;
        }
      }
    }
  }

}